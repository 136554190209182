const cities = [
  {
    city: 'København',
    places:
      'Royal Arena, Vega, Det ny teater, Huset, Ideal bar, Pumpehuset, Cirkusbygningen, Den grå hal',
    url: '/cities/copenhagen',
    id: 1,
    img: '../images/kbh.webp',
  },
  {
    city: 'Aarhus',
    places:
      'Train, ARoS, Tivoli Friheden, Ceres Arena, Aarhus Congress Center, Centralværkstedet, Sct pauls kirke, Musikhuset',
    url: '/cities/aarhus',
    id: 2,
    img: '../images/aarhus.webp',
  },
  {
    city: 'Odense',
    places: 'Odeon, Magasinet, Jyske Bank Arena, Dexter, Engen i fruens bøge',
    url: '/cities/odense',
    id: 3,
    img: '../images/odense.webp',
  },
  {
    city: 'Aalborg',
    places:
      'Musikkens hus, Aalborg Kongres & Kultur center, Europahallen, Aalborg hallen',
    url: '/cities/aalborg',
    id: 4,
    img: '../images/aalborg.webp',
  },
  {
    city: 'Esbjerg',
    places: 'Skibhøj, Granly speedway arena',
    url: '/cities/esbjerg',
    id: 5,
    img: '../images/esbjerg.webp',
  },
  {
    city: 'Randers',
    places:
      'Elro arena, Cafe Von Hatten, Brotoften, Sct. Peders kirke, Elvis Museet',
    url: '/cities/randers',
    id: 6,
    img: '../images/randers.webp',
  },
];

export default cities;
