import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { EventsContext } from '../../provider/EventProvider';
import Footer from '../Footer';
import Cookiebar from '../Cookiebar';
import usePrevious from '../../hooks/usePrevious';

function Layout({ children, withoutSVG, withoutHeader, isBlog = false }) {
  const { state } = useContext(EventsContext);
  const previousEvents = usePrevious(state.events);

  useEffect(() => {
    if (state.events && previousEvents === null) {
      if (window.location.pathname === '/events') return;
      navigate('/events');
    }
  }, [state.events]);

  return (
    <div>
      <main className="main-wrapper">
        {!withoutSVG && !withoutHeader && (
          <svg
            className="top-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path d="M0,224L80,202.7C160,181,320,139,480,144C640,149,800,203,960,186.7C1120,171,1280,85,1360,42.7L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z" />
          </svg>
        )}
        {children}
        {(!withoutSVG || isBlog) && (
          <svg
            className="bottom-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path d="M0,224L80,202.7C160,181,320,139,480,144C640,149,800,203,960,186.7C1120,171,1280,85,1360,42.7L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z" />
          </svg>
        )}
      </main>
      {(!withoutSVG || isBlog) && <Footer />}
      {process.env.NODE_ENV !== 'development' && <Cookiebar />}
    </div>
  );
}

export default Layout;
