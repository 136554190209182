import React from 'react';
import { Link } from 'gatsby';
import cities from '../CityCards/cities';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h2 className="footer-column__title">Oplevelser</h2>
        <div className="footer-column__links">
          {cities.map((city) => (
            <Link
              key={city.id}
              className="footer-column__links--link"
              to={city.url}
            >
              {city.city}
            </Link>
          ))}
        </div>
      </div>
      <div className="footer-column">
        <h2 className="footer-column__title">Zooticket</h2>
        <div className="footer-column__links">
          <Link className="footer-column__links--link" to="/">
            Forside
          </Link>
          <Link className="footer-column__links--link" to="/oplevelser">
            Oplevelser
          </Link>
          <Link className="footer-column__links--link" to="/about">
            Hvem er vi
          </Link>
          <Link className="footer-column__links--link" to="/cookies">
            Cookies og politik
          </Link>
          <Link className="footer-column__links--link" to="/sitemap.xml">
            Sitemap
          </Link>
        </div>
      </div>
      <div className="footer-column">
        <h2 className="footer-column__title">Kontakt</h2>
        <div className="footer-column__links">
          <Link className="footer-column__links--link" to="/tilfoej-begivenhed">
            Tilføj begivenhed
          </Link>
          <Link className="footer-column__links--link" to="/contact">
            Kontakt os
          </Link>
          <a
            className="footer-column__links--link"
            href="https://zooticket.co.uk"
          >
            Zooticket UK
          </a>
        </div>
      </div>
      <div className="copyright">
        Copyright {new Date().getFullYear()} @ Midear
      </div>
    </footer>
  );
}

export default Footer;
